import caudalimetro1 from '../caudalimetroddegases/caudalimetro1.jpeg'
import caudalimetro2 from '../caudalimetroddegases/caudalimetro2.jpeg'
import caudalimetro3 from '../caudalimetroddegases/caudalimetro3.jpeg'


const caudalimetroImages = [
    caudalimetro3,
    caudalimetro1,
    caudalimetro2
]

export default caudalimetroImages